.missionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}

.content {
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px;
}

.imageContainer {
  display: flex;
  width: 300px;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.missionTextAlign {
  text-align: justify;
  color: #fff;
}

.descriptorContainer {
width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleStyle{
  font-size: var(--font-ml)
}

@media (min-width: 768px) {
  .missionContainer {
    margin-top: 75px;
  }
  .missionTextAlign{
    text-align:left
  }
  .imageContainer {
    width: 275px;
    padding: unset;
    top: -70px;
    height: 100%;
  }
  .content {
    width: 80%;
    margin-top: 10%
  }
  .descriptorContainer {
    width: 280px;
    justify-content: flex-start;
    margin-left: 5%;
  }
  .titleStyle {
    width: 100%;
    font-size: var(--font-l)
  }
}

@media (min-width: 1024px) {
  .imageContainer{
    width: 350px;
  }

  .descriptorContainer {
    width: 350px;
  }

}

@media(min-width:1366px){
  .content{
    width: 70%;
  }
  .descriptorContainer {
    width: 450px;
  }
}

@media(min-width:1900px){
  .imageContainer{
    top:-90px;
    width: 500px;
  }
  .descriptorContainer{
    width: 600px;
  }
}
@media(min-width:2560px){
  .imageContainer{
    width: 650px;
    top: -135px
  }
  .descriptorContainer{
    width: 700px;
  }
  .content{
    width: 80%;
  }
}
